import React from "react";
// import { Link, NavLink } from "react-router-dom";

const blog = () => (
    <div>
        blog
    </div>
);

export default blog;
