import React from "react";
// import { Link, NavLink } from "react-router-dom";

const category = () => (
    <div>
        category
    </div>
);

export default category;
