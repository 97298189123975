import React from "react";
// import { Link, NavLink } from "react-router-dom";

const BlogDetail = () => (
    <div>
        BlogDetail
    </div>
);

export default BlogDetail;
